import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import StaticCard from './static_card';

const styles = (theme) => ({
    root: {
        padding: '15px 0',
        flexGrow: 1
    },
    paper: {
        height: 140,
        width: 100
    }
});

export function ShowCase(props) {
    const {classes: classNames, showCaseItems: items} = props;

    return (
        <Grid
            container
            className={classNames.root}
            justifyContent="center"
            spacing={2}
        >
            {items.map((item, index) => (
                <Grid key={index} item>
                    <StaticCard className={classNames.paper} staticCardItem={item} />
                </Grid>
            ))}
        </Grid>
    );
}

export default withStyles(styles)(ShowCase);
