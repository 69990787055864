/**
 * Returns the values of all the own properties of obj
 * @param {Object} obj - The object to get the values from
 * @returns {Array}
 */

 export function values(obj) {
    return Object.keys(obj).map(key => obj[key]);
 }

export function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}