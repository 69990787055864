import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import {isMobile} from 'react-device-detect';

const styles = (theme) => ({
    root: {
        padding: isMobile ? '0 10px 10px 0' :'15px 15px 15px 0'
    }
});

export function PageTitle(props) {
    const {classes: classNames, titleText} = props;

    return (
        <div className={classNames.root}>
            <CssBaseline />
            <Typography variant="h5" noWrap>
                {titleText}
            </Typography>
        </div>
    );
}

export default withStyles(styles)(PageTitle);
