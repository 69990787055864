import React from 'react';
import Footer from './footer';
import AppLayout from './app_layout';


export default class App extends React.Component {
  render() {
    return (
      <>
        <AppLayout />
        <Footer />
      </>
    );
  }
}
