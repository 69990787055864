import React from 'react';
import './footer.css';

export default class Footer extends React.Component {
  render() {
    return (
      <div className="Footer">
          @yCalculators 2021. All rights reserved.
      </div>
    );
  }
}
