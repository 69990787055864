import React from 'react';
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {GiAges} from 'react-icons/gi';
import {FaFunnelDollar, FaPercentage} from 'react-icons/fa';
import {IconContext} from 'react-icons';
import ContentLayout from './content_layout';
import {NavLink} from 'react-router-dom';
import {
    COLOR_PALETTE,
    MODULE_LIST,
    ROUTE_MAP,
    DRAWER_WIDTH,
    MODULE_IDS
} from './constants';
import productName from './productName.png';
import logo from './productLogo.png';
import {isMobile} from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: DRAWER_WIDTH,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: 36
    },
    hide: {
        display: 'none'
    },
    drawer: !isMobile ? {
        width: DRAWER_WIDTH,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    } : {},
    drawerOpen: {
        width: DRAWER_WIDTH,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1
        }
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    },
    navigationLinks: {
        textDecoration: 'none',
        color: COLOR_PALETTE.VIOLET_BLUE
    },
    title: {
        textDecoration: 'none',
        color: COLOR_PALETTE.LIGHT_PERIWINKLE
    }
}));

const ListItemIconMap = {
    [MODULE_IDS.PERCENTAGE_CALCULATOR]: <FaPercentage />,
    [MODULE_IDS.COMPOUND_INTEREST_CALCULATOR]: <FaFunnelDollar />,
    [MODULE_IDS.AGE_CALCULATOR]: <GiAges />
};

export default function NavigationLayout() {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <NavLink to="/" className={classes.title}>
                        <img width={51} height={50} src={logo} alt="Logo"></img>
                        <img width={200} height={32} src={productName} alt="Product Name"></img>
                    </NavLink>
                </Toolbar>
            </AppBar>
            <Drawer
                variant={isMobile && !open ? 'temporary' : 'permanent'}
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open
                    })
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? (
                            <ChevronRightIcon />
                        ) : (
                            <ChevronLeftIcon />
                        )}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    {MODULE_LIST.map(({id, name}) => (
                        <NavLink
                            to={ROUTE_MAP[id]}
                            className={classes.navigationLinks}
                            key={id}
                        >
                            <ListItem button onClick={handleDrawerClose}>
                                <ListItemIcon>
                                    <IconContext.Provider
                                        value={{
                                            color: COLOR_PALETTE.VIOLET_BLUE,
                                            size: '1.7em'
                                        }}
                                    >
                                        {ListItemIconMap[id]}
                                    </IconContext.Provider>
                                </ListItemIcon>
                                <ListItemText primary={name} />
                            </ListItem>
                        </NavLink>
                    ))}
                </List>
                <Divider />
            </Drawer>
            <ContentLayout />
        </div>
    );
}
