import React, {lazy} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PageTitle from './page_title';
import {Switch, Route} from 'react-router-dom';
import ShowCase from './show_case';
import {values} from './utils.js';
import {MODULE_IDS, MODULE_NAMES, ROUTE_MAP} from './constants';
import PercentageCoverImage from './images/percentage_cover.webp';
import AgeCoverImage from './images/age_cover.webp';
import CompoundInterestCoverImage from './images/compound_interest_cover.webp';
import {isMobile} from 'react-device-detect';
import {Suspense} from 'react';

const PercentageCalculator = lazy(() => import('./percentage_calculator'));
const CompoundInterestCalculator = lazy(() =>
    import('./compound_interest_calculator')
);
const AgeCalculator = lazy(() => import('./age_calculator'));

const styles = (theme) => ({
    actionArea: {
        flexGrow: 1,
        padding: theme.spacing(3),
        overflowX: isMobile ? 'hidden' : 'unset'
    },
    content: {
        padding: theme.spacing(0, isMobile ? 0 : 4)
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    },
    loading: {

    }
});

const {PERCENTAGE_CALCULATOR, AGE_CALCULATOR, COMPOUND_INTEREST_CALCULATOR} =
    MODULE_IDS;
const contentData = {
    [PERCENTAGE_CALCULATOR]: {
        id: PERCENTAGE_CALCULATOR,
        path: ROUTE_MAP[PERCENTAGE_CALCULATOR],
        title: MODULE_NAMES[PERCENTAGE_CALCULATOR],
        description:
            'A simple tool to calculate percentage and change in percentages.',
        imageUrl: PercentageCoverImage
    },
    [COMPOUND_INTEREST_CALCULATOR]: {
        id: COMPOUND_INTEREST_CALCULATOR,
        path: ROUTE_MAP[COMPOUND_INTEREST_CALCULATOR],
        title: MODULE_NAMES[COMPOUND_INTEREST_CALCULATOR],
        description: 'Calculate compound interest easily',
        imageUrl: CompoundInterestCoverImage
    },
    [AGE_CALCULATOR]: {
        id: AGE_CALCULATOR,
        path: ROUTE_MAP[AGE_CALCULATOR],
        title: MODULE_NAMES[AGE_CALCULATOR],
        description: `Calculate a person's age or interval between any two dates.`,
        imageUrl: AgeCoverImage
    }
};

export function ContentLayout(props) {
    const {classes: classNames} = props;
    const {[PERCENTAGE_CALCULATOR]: percent} = contentData;

    return (
        <main className={classNames.actionArea}>
            <div className={classNames.toolbar} />
            <div className={classNames.content}>
                <div>
                    <Suspense
                        fallback={
                            <div className={classNames.loading}>Loading...</div>
                        }
                    >
                        <Switch>
                            <Route path={percent.path}>
                                <PageTitle titleText={percent.title} />
                                <PercentageCalculator />
                            </Route>

                            <Route path="/compound-interest-calculator">
                                <PageTitle titleText="Compound Interest Calculator" />
                                <CompoundInterestCalculator />
                            </Route>

                            <Route path="/age-calculator">
                                <PageTitle titleText="Age Calculator" />
                                <AgeCalculator />
                            </Route>

                            <Route path="/">
                                <PageTitle titleText="Welcome to yCalculators" />
                                <ShowCase showCaseItems={values(contentData)} />
                            </Route>
                        </Switch>
                    </Suspense>
                </div>
            </div>
        </main>
    );
}

export default withStyles(styles)(ContentLayout);
