import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {NavLink} from 'react-router-dom';
import {LazyLoadComponent} from 'react-lazy-load-image-component';

const useStyles = makeStyles({
    root: {
        width: 345,
        height: 375,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    media: {
        height: 180
    },
    navLinks: {
        textDecoration: 'none',
        color: 'unset'
    },
    button: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        padding: '10px 20px',   
        fontSize: '16px'
    }
});

export default function StaticCard(props) {
    const classes = useStyles();
    const {staticCardItem} = props;
    const {imageUrl, title, description, path} = staticCardItem;

    return (
        <NavLink to={path} className={classes.navLinks}>
            <Card className={classes.root}>
                <CardActionArea>
                    <LazyLoadComponent>
                        <CardMedia
                            className={classes.media}
                            image={imageUrl}
                            title={title}
                        />
                    </LazyLoadComponent>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {title}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            {description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <Button size="small" color="primary" className={classes.button}>
                    View
                </Button>
            </Card>
        </NavLink>
    );
}
