export const DRAWER_WIDTH = 240; // px

export const COLOR_PALETTE = {
    VIOLET_BLUE: '#3f51b5',
    LIGHT_CORAL: '#ef6f6c',
    BANANA_MANIA: '#edd9a3',
    XIKETIC: '#171219',
    LIGHT_STEEL_BLUE: '#b4c5e4',
    WILD_BLUE_YONDER: '#aeb4cf',
    LIGHT_PERIWINKLE: '#c6cadc',
    JET: '#3f3c41',
    WHITE: '#ffffff'
};

export const MODULE_IDS = {
    PERCENTAGE_CALCULATOR: 'percentage_calculator',
    COMPOUND_INTEREST_CALCULATOR: 'compound_interest_calculator',
    AGE_CALCULATOR: 'age_calculator'
};

export const MODULE_NAMES = {
    [MODULE_IDS.PERCENTAGE_CALCULATOR]: 'Percentage Calculator',
    [MODULE_IDS.COMPOUND_INTEREST_CALCULATOR]: 'Compound Interest Calculator',
    [MODULE_IDS.AGE_CALCULATOR]: 'Age Calculator'
};

export const ROUTE_MAP = {
    [MODULE_IDS.PERCENTAGE_CALCULATOR]: '/percentage-calculator',
    [MODULE_IDS.COMPOUND_INTEREST_CALCULATOR]: '/compound-interest-calculator',
    [MODULE_IDS.AGE_CALCULATOR]: '/age-calculator'
};

export const MODULE_LIST = [
    {id: MODULE_IDS.PERCENTAGE_CALCULATOR, name: MODULE_NAMES[MODULE_IDS.PERCENTAGE_CALCULATOR]},
    {id: MODULE_IDS.COMPOUND_INTEREST_CALCULATOR, name: MODULE_NAMES[MODULE_IDS.COMPOUND_INTEREST_CALCULATOR]},
    {id: MODULE_IDS.AGE_CALCULATOR, name: MODULE_NAMES[MODULE_IDS.AGE_CALCULATOR]}
];
