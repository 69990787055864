import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import NavigationLayout from './navigation_layout';

const styles = (theme) => ({
    root: {}
});

export function AppLayout(props) {
    // const {classes: classNames} = props;

    return <NavigationLayout />;
}

export default withStyles(styles)(AppLayout);
